<template lang="pug">
Modal(
    :mask-closable="true",
    :styles="{top: '50px', margin: '0px auto 50px'}"
    :value="visible"
    @on-visible-change="onVisibleChange"
    @on-cancel="onClose"
    :title="modelTitle"
)
  Form.modal-editor(
    :model="formData"
    ref="poolEditorForm"
    :rules="poolEditorRule"
  )
    template(v-if="formData")
      .content-part(v-if="isEditMode")
        Row(align="middle")
          Col.title(span="9")
            h3 {{$t('Pool id')}}
          Col.val(span="9" offset="6")
            span {{ formData.poolId }}

        Row(align="middle")
          Col.title(span="9")
            h3 {{$t('Pool category')}}
          Col.val(span="9" offset="6")
            span {{ $t(formData.category) }}

        Row(align="middle")
          Col.title(span="9")
            h3 {{$t('Pool bet count')}}
          Col.val(span="9" offset="6")
            span {{ currencyFormat(formData.betCount, { noSymbol: true }) }}

      .content-part(v-if="formData.category !== 'jp-major' && formData.category !== 'jp-mini' && formData.category !== 'jp-minor' && formData.category !== 'jp-grand'")
        Divider(orientation="left" v-if="isEditMode")
          Icon(
            type="ios-briefcase",
            style="margin-right: 6px"
          )
          span {{$t('Game info')}}

        Row(align="middle")
          Col.title(span="9")
            h3 {{$t('Game name')}}
          Col.val(span="9" offset="6")
            span {{ formData.game.name }}

        Row(align="middle")
          Col.title(span="9")
            h3 {{$t('Game id')}}
          Col.val(span="9" offset="6")
            span {{ formData.gameId }}

        Row(align="middle")
          Col.title(span="9")
            h3 {{$t('Game category')}}
          Col.val(span="9" offset="6")
            span {{ formData.game.category }}

        Row(align="middle")
          Col.title(span="9")
            h3 {{$t('Game mechanism')}}
          Col.val(span="9" offset="6")
            span {{ $t(formData.game.mechanism) }}

      .content-part
        Divider(orientation="left")
          Icon(
            type="ios-briefcase",
            style="margin-right: 6px"
          )
          span {{$t('Pool settings')}}

        FormItem(prop="prize" v-if="isEditMode")
          Row(align="middle")
            Col.title(span="9")
              h3 {{$t('Pool prize')}}
            Col.val(span="9" offset="6")
              Input(
                v-model="formData.prize"
                :placeholder="$t(`prize`)"
              )

        FormItem(prop="category" v-if="isCreateMode")
          Row(align="middle")
            Col.title(span="9")
              h3 {{$t('Pool category')}}
            Col(span="9" offset="6")
              Select(
                v-model="formData.category"
                :placeholder="$t('Select pool category')"
              )
                Option(v-for="item in categoryList" :value="item.value" :key="item.value") {{ item.label }}

        FormItem(prop="gameClass" v-if="isCasino")
          Row(align="middle")
            Col.title(span="9")
              h3 {{$t('Game class')}}
            Col(span="9" offset="6")
              Select(
                v-model="formData.gameClass"
                :placeholder="$t(`Select game class`)"
              )
                Option(v-for="item in gameClassList" :value="item.value" :key="item.value") {{ item.label }}
        FormItem(prop="initialLevel")
          Row(align="middle")
            Col.title(span="9")
              h3 {{$t('Pool initial level')}}
            Col.val(span="9" offset="6")
              Input(
                v-model="formData.initialLevel"
                :placeholder="$t(`Pool initial level`)"
              )

        FormItem(prop="releaseLevel")
          Row(align="middle")
            Col.title(span="9")
              h3 {{$t('Pool release level')}}
            Col.val(:span="releaseLevelInputWidth()" offset="6")
              Input(
                v-model="formData.releaseLevel"
                :placeholder="$t(`Pool release level`)"
              )
            Col.val(span="2" v-if="showPoolMetaEditor()")
              Tooltip(
                placement="top"
                :content="$t('Pool meta settings')"
              )
                Button.actions(
                  type="primary",
                  icon="md-options",
                  @click="editPoolMeta"
                )

        FormItem(prop="maxStake")
          Row(align="middle")
            Col.title(span="9")
              h3 {{$t('Pool maximum stake')}}
            Col.val(span="9" offset="6")
              Input(
                v-model="formData.maxStake"
                :placeholder="$t(`Pool maximum stake`)"
              )

        FormItem(prop="minStake")
          Row(align="middle")
            Col.title(span="9")
              h3 {{$t('Pool minimum stake')}}
            Col.val(span="9" offset="6")
              Input(
                v-model="formData.minStake"
                :placeholder="$t(`Pool minimum stake`)"
              )

        FormItem(prop="drawRate")
          Row(align="middle")
            Col.title(span="9")
              h3 {{$t('Jackpot draw chance')}}
            Col.val(span="9" offset="6")
              Input(
                v-model="formData.drawRate"
                :placeholder="$t(`Jackpot draw chance`)"
              )

        FormItem(prop="winningRatio")
          Row(align="middle")
            Col.title(span="9")
              h3 {{$t('Jackpot draw rate')}}
            Col.val(span="9" offset="6")
              Input(
                v-model="formData.winningRatio"
                :placeholder="$t(`Jackpot draw rate`)"
              )

        FormItem(prop="actived")
          Row(align="middle")
            Col.title(span="9")
              h3 {{$t('Pool switch')}}
            Col.val(span="12" offset="3")
              i-switch(
                v-model="formData.actived"
                :true-value="true"
                :false-value="false"
              )
                Icon(slot="open" type="md-checkmark")
                Icon(slot="close" type="'md-close")

  .footer(slot='footer')
    template(v-if="!isViewMode")
      Row
        Col(span="6")
          //- Button(icon="md-add" :disabled="isUpdating" @click="addTemplate" v-if="!isAllMechanism") {{$t('Add template')}}
          //- Button(icon="md-add" :disabled="isUpdating" @click="addTemplate" v-if="!isAllMechanism") {{$t('Cancel')}}
        Col(span="18")
          Button(icon="md-close" @click="onClose") {{$t('Cancel')}}
          Button(icon="md-checkmark" type="primary" @click="handleSubmit" :loading="isUpdating") {{$t('Confirm')}}
    template(v-if="isViewMode")
      Button(icon="md-close" @click="onClose") {{$t('Close')}}

  PoolMetaEditor(
    :meta-data="poolMetaData",
    :on-update="onPoolMetaUpdate"
    :on-close="onPoolMetaClose"
    :visible="poolMetaEditorVisible"
  )

</template>
<script>
import Currency from '~m/currency'
import NumberUtil from '~m/number-util'
import PoolMetaEditor from '~c/pool-meta-editor'
import { mapActions } from 'vuex'
import to from 'await-to-js'
export default {
  name: 'pool-editor',
  components: {
    PoolMetaEditor
  },
  mixins: [Currency, NumberUtil],
  data () {
    return {
      poolMetaEditorVisible: false,
      poolMetaData: null,
      isUpdating: false,
      coolingList: [
        {
          value: 'share',
          label: this.$t('Cooling share')
        },
        {
          value: 'game',
          label: this.$t('Cooling game')
        }
      ],
      categoryList: [
        {
          value: 'jp4',
          label: this.$t('jp4')
        },
        {
          value: 'jp',
          label: this.$t('jp')
        },
        {
          value: 'jps',
          label: this.$t('jps')
        },
        {
          value: 'jpm',
          label: this.$t('jpm')
        },
        {
          value: 'jpl',
          label: this.$t('jpl')
        }
      ],
      gameClassList: [
        {
          value: '0',
          label: this.$t('class0')
        },
        {
          value: '1',
          label: this.$t('class1')
        },
        {
          value: '2',
          label: this.$t('class2')
        },
        {
          value: '3',
          label: this.$t('class3')
        },
        {
          value: '4',
          label: this.$t('class4')
        }
      ]
    }
  },

  props: {
    formData: Object,
    editType: String,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean,
    isCasino: Boolean,
    mechanism: String,
    openTemplateEdit: Function,
    isAllMechanism: Boolean
  },

  watch: {
    formData: {
      handler (nv, ov) {
        if (!this.formData) {
          return
        }
        this.parseObjectNumber(this.formData)
        if (this.formData && !this.formData.isDirty) {
          this.formData.isDirty = !this.formData.isDirty
        }
      },
      deep: true
    }
  },

  computed: {
    // maxEnterRate () {
    //   if (process.env.VUE_APP_DEVELOPMENT === '1') {
    //     return 0.5
    //   }
    //   return 0.01
    // },
    poolEditorRule () {
      if (!this.formData) return {}

      let validatorData = {
        minStake: [{ required: true, validator: this.numberValidator, trigger: 'blur' }],
        maxStake: [{ required: true, validator: this.numberValidator, trigger: 'blur' }],
        releaseLevel: [{ required: true, validator: this.numberValidator, trigger: 'blur' }],
        initialLevel: [{ required: true, validator: this.numberValidator, trigger: 'blur' }],
        prize: [{ required: true, validator: this.numberValidator, trigger: 'blur' }],
        gameClass: [{ required: true, validator: this.numberValidator, trigger: 'blur' }]
      }

      if (this.mechanism && this.mechanism !== 'all') {
        const allowData = {
          poolRate: [{ required: true, validator: this.rateValidator, trigger: 'blur' }],
          fgMiniWinRate: [{ required: true, validator: this.numberValidator, trigger: 'blur' }],
          fgWinTolerance: [{ required: true, validator: this.numberValidator, trigger: 'blur' }],
          fgEnterRate: [{ required: true, validator: this.numberValidator, trigger: 'blur' }],
          fgMaxEnterRate: [{ required: true, validator: this.numberValidator, trigger: 'blur' }],
          mgHighWinChance: [{ required: true, validator: this.numberValidator, trigger: 'blur' }],
          mgMiniWinRate: [{ required: true, validator: this.numberValidator, trigger: 'blur' }],
          mgWinTolerance: [{ required: true, validator: this.numberValidator, trigger: 'blur' }],
          mgNoWinChance: [{ required: true, validator: this.numberValidator, trigger: 'blur' }],
          mgNoWinChanceLimit: [{ required: true, validator: this.numberValidator, trigger: 'blur' }],
          slotTablesCount: [{ validator: this.numberOrEmptyValidator, trigger: 'blur' }]
        }
        validatorData = { ...validatorData, ...allowData }
      }
      return validatorData
    },

    isEditMode () {
      if (!this.formData) return false
      return this.editType === 'edit'
    },

    isCreateMode () {
      if (!this.formData) return false
      return this.editType === 'new'
    },

    isViewMode () {
      if (!this.formData) return false
      return this.editType === 'view'
    },

    modelTitle () {
      if (!_.size(this.formData)) return ''
      const { category, poolId, operator, game } = this.formData
      const { name, fullname } = operator
      const meta = {
        name: name || fullname,
        category,
        poolId
      }
      switch (this.editType) {
        case 'new':
          return this.$t(`Add {game} pool of {operator}`, { game: game.name, operator: operator.name || operator.fullname })
        case 'edit':
          return this.$t(`Edit pool {category}({poolId}) of {name}`, meta)
        case 'view':
          return this.$t(`View pool {category}({poolId}) of {name}`, meta)
      }

      return ''
    }
  },
  methods: {
    ...mapActions('poolMeta', {
      updatePoolMeta: 'update'
    }),
    numberValidator (rule, value, callback) {
      let { field } = rule
      if (value === '') {
        callback(new Error(this.$t(`This field is required`)))
      } else if (!_.isNumber(value)) {
        callback(new Error(this.$t('This field accept only numbers')))
      }
      if (field === 'releaseLevel' && value <= 0) {
        callback(new Error(this.$t('profile input error')))
      }
      callback()
    },

    numberOrEmptyValidator (rule, value, callback) {
      if (value && !_.isNumber(value)) {
        callback(new Error(this.$t('This field accept only numbers')))
      } else {
        callback()
      }
    },

    rateValidator (rule, value, callback) {
      if (value === '') {
        callback(new Error(this.$t(`This field is required`)))
      } else if (!_.isNumber(value) || value > 1 || value < 0.001) {
        callback(new Error(this.$t('This field accept only numbers')))
      } else {
        callback()
      }
    },

    async handleSubmit () {
      console.log('handleSubmit')
      // let valid = await this.$refs.poolEditorForm.validate()
      // if (!valid) {
      //   return false
      // }
      this.isUpdating = true

      await this.onUpdate()

      this.isUpdating = false
    },

    onVisibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        this.onClose('hold')
      }

      if (this.metaData) this.metaData.isReady = true
    },

    onPoolMetaClose () {
      this.poolMetaEditorVisible = false
    },

    editPoolMeta () {
      this.poolMetaData = { ...this.formData.meta }
      this.poolMetaEditorVisible = true
    },

    async onPoolMetaUpdate () {
      if (!this.poolMetaData.isDirty) {
        this.$Message.success(this.$t('Data no change'))
      } else {
        const dataUpdate = { ...this.poolMetaData }
        // 強轉數字類型
        this.parseObjectNumber(dataUpdate)

        const [err] = await to(this.updatePoolMeta({ id: this.formData.poolId, data: dataUpdate }))
        if (err) {
          return this.$Message.error(this.$t('Edit pool error'))
        }
        this.$Message.success(this.$t('Edit pool release level seccess'))
      }
      this.closePoolMetaEditor()
    },

    closePoolMetaEditor (hold) {
      this.poolMetaEditorVisible = false
      setTimeout(() => {
        this.poolMetaData = null
        // this.onUpdate()
      }, 500)
    },

    showPoolMetaEditor () {
      if (this.formData.category === 'jp') {
        return false
      } else {
        return true
      }
    },

    // 彩池滿水標準的輸入框寬度採動態調整
    releaseLevelInputWidth () {
      if (this.formData.category === 'jp') {
        // 機台彩池沒有按鈕, 寬度設定為 9
        return 9
      } else {
        // 大廳彩池沒有按鈕, 寬度設定為 7, 預留按鈕的空間
        return 7
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.input-prefix
  line-height: 34px
  position: absolute
  left: 0
  padding-left: 8px
  color: #aaa
</style>
