<template lang="pug">
Modal(
  width="618"
  :mask-closable="true"
  :closable="false"
  :styles="{top: '50px', margin: '0px auto 50px'}"
  :value="visible"
  @on-visible-change="visibleChange"
  @on-cancel="onClose"
)
  Row(:gutter="20")
    Col(span="18")
      Alert(type="warning" class="tip") {{ $t('Pool release level chance settings') }}
    Col.text-align-right(span="6")
      Tag(size="large") {{ $t('Expected Value') }} {{ calculateRtp(getObjectKeys(), getObjectValues(), 1) }}
  Row(:gutter="20"
    v-for="(symbol, idx) in getObjectKeys()"
    :key="`${symbol}-${idx}`"
    align="middle"
    )
    Col(span="4")
      h4.text-middle()
        //- span {{ symbol }}
        Input(
          type="text"
          v-model="getObjectKeys()[idx]"
          style="float: left; width: 52px"
          :disabled="viewMode"
        )
    Col(span="11")
      Slider(
        v-model="getObjectValues()[idx]"
        :min="0"
        :max="getSliderMax(getObjectValues())"
        :step="sliderStep"
        @on-input="onSliderChange"
        :disabled="viewMode"
      )
    Col(span="3")
      InputNumber(
        :step="sliderStep"
        v-model="getObjectValues()[idx]"
        :disabled="viewMode"
        style="width: 62px;"
      )
    Col(span="3")
      span(style="float: right;") {{ symbolPercent(getObjectValues(), idx)}}
    Col(span="3")
      Icon.add-key-btn(
        type="md-add"
        v-if="idx !== getObjectValues().length - 1"
        @click="addKeyValues(idx)"
      )
      Icon.remove-key-btn(
        type="md-remove"
        v-if="idx !== 0"
        @click="removeKeyValues(idx)"
      )
  .footer(slot='footer')
    Row
      Col(span="6")

      Col(span="18")
        Button(icon="md-close" @click="onClose" :disabled="isUpdating") {{$t('Cancel')}}
        Button(
          v-if="!viewMode"
          icon="md-checkmark"
          type="primary"
          @click="poolMetaConfirm"
          :loading="isUpdating"
          ) {{$t('Confirm')}}

</template>
<script>
import NumberUtil from '~m/number-util'
import Thumbnail from '~m/thumbnail'
import AppTable from '~c/app-table'
export default {
  name: 'pool-meta-editor',
  components: {
    AppTable
  },
  data () {
    return {
      sliderStep: 100,
      currentTab: null,
      previousTab: null,
      isUpdating: false
    }
  },
  mixins: [NumberUtil, Thumbnail],
  props: {
    metaData: Object,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean,
    viewMode: Boolean
  },
  watch: {
    metaData: {
      handler (nv, ov) {
        if (this.metaData && this.metaData.isReady && !this.metaData.isDirty) {
          this.metaData.isDirty = this.metaData.isReady && !this.metaData.isDirty
        }
      },
      deep: true
    }
  },
  computed: {},
  mounted () {},
  methods: {
    getObjectKeys () {
      if (!this.metaData) {
        return []
      }
      return this.metaData.releaseLevelKeys ? this.metaData.releaseLevelKeys : []
    },
    getObjectValues () {
      if (!this.metaData) {
        return []
      }
      return this.metaData.releaseLevelValues ? this.metaData.releaseLevelValues : []
    },
    addKeyValues (index) {
      const keys = this.getObjectKeys()
      const values = this.getObjectValues()
      const [v1, v2] = [parseInt(keys[index]), parseInt(keys[index + 1])]
      const key = v1 + ((v2 - v1) / 2)
      keys.splice(index + 1, 0, key)
      values.splice(index + 1, 0, values[index])
    },
    removeKeyValues (index) {
      const keys = this.getObjectKeys()
      const values = this.getObjectValues()
      keys.splice(index, 1)
      values.splice(index, 1)
    },
    getSliderMax (myValues) {
      if (_.isObject(myValues)) {
        myValues = _.values(myValues)
      }
      let maxVal = _.max(myValues)
      if (maxVal < 1000) {
        maxVal = 1000
      } else {
        maxVal += 1000
      }
      return maxVal
    },
    symbolPercent (values, index) {
      const data = values
      const total = _.sum(data)
      return (data[index] / total * 100).toFixed(3) + '%'
    },
    calculateRtp (keys, values, odds = 1) {
      let rtp = 0
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        const rate = this.symbolPercent(values, i)
        rtp += parseInt(key, 10) * Number(rate.replace('%', '')) / 100
      }

      rtp /= odds
      return rtp.toFixed(3)
    },
    async poolMetaConfirm () {
      this.isUpdating = true
      await this.onUpdate()
    },
    getAlert (tab) {
      let Alert = _.find(this.tabs, p => p.name === tab) || 'error'
      return this.$t(`${Alert.method} Tips`)
    },
    visibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        this.onClose()
      }
      this.metaData.isReady = true
    },

    onSliderChange (val) {
      this.metaData.isDirty = true
    }
  }
}
</script>
<style lang="sass" scoped>
.edit-title
  height: 40px
  p
    height: auto

.rtp-list
  // padding: 4px 12px
  // width: auto
  // float: left
  .stake-level
    font-weight: bold
    margin-right: 8px
    width: 40px
    text-align: right
    display: inline-block

  .stake-rtp
    width: 48px
    text-align: right
    display: inline-block

.current-rtp, .text-red
  // font-weight: bold
  color: #ed4014

.box-rate-alert
  font-weight: bold
  color: #ed4014
  text-align: right

.target-rtp
  color: #c5c8ce

.rtp-divider
  margin: 0 4px

.pull-right
  float: right

.text-left
  text-align: left

.chance-input
  text-align: right
  padding-right: 4px

.margin-top-6
  margin-top: 6px

.margin-bottom-6
  margin-bottom: 6px

.mg-box-con
  width: 65px
  float: left

.text-middle
  text-align: right
  vertical-align: middle
  &.pointer
    cursor: pointer

.tip
  margin-bottom: 24px

.content
  width: 100%
  min-height: 50%

.tag-border
  border: 1px solid #dddfe3!important

.text-align-right
  text-align: center

.level-item
  margin: 12px

.submenu
  padding: 16px 0
  background-color: #f8f8f9

.condition-title
  margin-left: 10px
  margin-right: 10px
  margin-top: 2px

.condition-row
  margin-left: 10px
  margin-right: 10px
  margin-top: 10px

.condition-view
  margin-left: 10px
  margin-right: 10px
  margin-top: 16px
  margin-bottom: 20px

.add-key-btn, .remove-key-btn
  margin-left: 4px
  font-size: 16px
  cursor: pointer
  &.add-key-btn
    color: #19be6b
    &:hover
      color: darken(#19be6b, 30%)
  &.remove-key-btn
    color: #ed4014
    &:hover
      color: darken(#ed4014, 30%)
</style>
